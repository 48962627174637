<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Academia awardees
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Send university emails"
              @click="sendEmails()"
            ><i
              data-feather="send"
            /> Send email to universities ...</a>
          </div>
        </div>
        <div class="content-body content-body--senior-call">
          <div
            v-if="!loading"
            class="row"
          >
            <div class="col-12">
              <CompleteTable
                :sorted-field="sortedField"
                :data="users"
                :sort-mapping="sortMapping"
                :title="'awardees'"
                :total="total"
                :fetch-path="'academyAwarded/fetch'"
                :filter-path="'academyAwarded/filter'"
                :actions="true"
                @selectedItems="saveSelectedItems"
              >
                <template v-slot="{field}">
                  <td
                    class="text-end"
                  >
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <i data-feather="more-vertical" />
                      </template>
                      <div
                        class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                      >
                        <b-dropdown-item
                          :href="$router.resolve({name: 'admin.calls.academy_awarded.show', params: { id: field.id } }).href"
                          target="_blank"
                        >
                          <i data-feather="file" />
                          Justification history
                        </b-dropdown-item>
                        <b-dropdown-item
                          :href="$router.resolve({name: 'awarded.awardees-list-edit', params: { id: field.id } }).href"
                          target="_blank"
                        >
                          <i data-feather="edit" />
                          Edit public data
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="true"
                          @click="downloadZip(field.id)"
                        >
                          <i data-feather="download" />
                          Download documents
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="field.economic_document"
                          :href="field.economic_document.url"
                          target="_blank"
                        >
                          <i data-feather="download" />
                          Download economic memoir
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="false"
                          @click="openFilters(field.user.id, field.status)"
                        >
                          <i data-feather="user" />
                          Contact information
                        </b-dropdown-item>
                        <b-dropdown-item @click="$router.push({name: 'admin.users.edit', params: { id: field.user.id }})">
                          <i
                            class="me-50"
                            data-feather="edit"
                          />
                          Edit profile
                        </b-dropdown-item>
                        <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                          <i
                            class="me-50"
                            data-feather="eye"
                          /> View traces
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </td>
                </template>
                <template #customfilters>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      @change="saveFilter($event.target.value, 'name')"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Universities</label>
                    <v-select
                      multiple
                      label="name"
                      :options="institutions"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'hosts')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Call</label>
                    <v-select
                      multiple
                      label="title"
                      :options="convos"
                      :get-option-key="option => option.id"
                      @input="saveFilter($event, 'calls')"
                      @search="onSearch({ name: $event, type: 'academy' }, 'convo/filter')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Areas</label>
                    <v-select
                      multiple
                      label="name"
                      :options="areas"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'areas')"
                      @search="onSearch({ name: $event }, 'areas/filter')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Active to justify</label>
                    <v-select
                      label="name"
                      :options="[
                        {label: 'Yes', name: 'Active', value: true},
                        {label: 'No', name: 'Inactive', value: false},
                      ]"
                      :get-option-key="option => option.value"
                      @input="saveFilter($event, 'active_to_justify')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Active to edit</label>
                    <v-select
                      label="name"
                      :options="[
                        {label: 'Yes', name: 'Active', value: true},
                        {label: 'No', name: 'Inactive', value: false},
                      ]"
                      :get-option-key="option => option.value"
                      @input="saveFilter($event, 'active_to_edit')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >ERC</label>
                    <v-select
                      multiple
                      label="name"
                      :options="ercs"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'ercs')"
                      @search="onSearch({ name: $event }, 'erc/filter')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Former</label>
                    <v-select
                      label="name"
                      :options="[
                        {label: 'Yes', name: 'Former', value: true},
                        {label: 'No', name: 'No former', value: false},
                      ]"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'former')"
                    />
                  </div>
                  <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Status</label>
                    <v-select
                      label="name"
                      :options="[
                        {label: 'Offered', name: 'Offered', value: 7},
                        {label: 'Accepted', name: 'Accepted', value: 8},
                        {label: 'Rejected', name: 'Rejected', value: 10},
                        {label: 'Renounced', name: 'Renounced', value: 11},
                        {label: 'Diseased', name: 'Diseased', value: 14},
                      ]"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'status')"
                    />
                  </div>
                  <!-- <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Acceptance doc</label>
                    <v-select
                      multiple
                      label="name"
                      :options="[
                        {label: 'All', name: 'All', value: 0},
                        {label: 'Yes', name: 'Yes', value: true},
                        {label: 'No', name: 'No', value: false},
                      ]"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'acceptance_doc')"
                    />
                  </div> -->
                  <!-- <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Justification history</label>
                    <v-select
                      multiple
                      label="name"
                      :options="[
                        {label: 'All', name: 'All', value: 0},
                        {label: 'Yes', name: 'Completed', value: true},
                        {label: 'No', name: 'Pending year', value: false},
                      ]"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'justification_history')"
                    />
                  </div> -->
                  <!-- <div class="mb-1">
                    <label
                      for="name"
                      class="form-label"
                    >Economic justification</label>
                    <v-select
                      multiple
                      label="name"
                      :options="[
                        {label: 'All', name: 'All', value: 0},
                        {label: 'Yes', name: 'Yes', value: true},
                        {label: 'No', name: 'No', value: false},
                      ]"
                      :get-option-key="option => option.name"
                      @input="saveFilter($event, 'economic_justification')"
                    />
                  </div> -->
                </template>
                <template #info-sheet-item>
                  <div class="offcanvas-body offcanvas-body--view">
                    <h4 class="mb-2">
                      <strong class="text-success">{{ selectedItems.length }}</strong> selected users
                    </h4>
                    <ul
                      id="basic-list-group"
                      class="list-group"
                    >
                      <li class="list-group-item draggable">
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-8"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="8"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-8"
                              >Validate Offered</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr>
                    <h5>Active edit profile</h5>
                    <ul
                      id="basic-list-group"
                      class="list-group"
                    >
                      <li class="list-group-item draggable"> <!-- edit to not edit 1 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-1"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="1"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-1"
                              >Change active edit profile to Not active edit profile</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable"> <!-- not edit to edit 5 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-5"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="5"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-5"
                              >Change Not edit profile to edit profile</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr>
                    <h5>Active justifications</h5>
                    <ul class="list-group">
                      <li class="list-group-item draggable"> <!-- justify to not justify 2 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-2"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="2"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-2"
                              >Change active justify to Not active justify</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable"> <!-- not justify to justify 6 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-6"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="6"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-6"
                              >Change Not to justify to justify</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr>
                    <h5>Active web public</h5>
                    <ul class="list-group">
                      <li class="list-group-item draggable"> <!-- web public to not web public 3 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-3"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="3"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-3"
                              >Change web public to Not web public</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable"> <!-- not web public to web public 7 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-7"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="7"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-7"
                              >Change Not web public to web public</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr>
                    <h5>Former</h5>
                    <ul class="list-group">
                      <li class="list-group-item draggable"> <!-- former to not former 4 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-4"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="4"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-4"
                              >Change former to Not former</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item draggable"> <!-- not former to former 9 -->
                        <div class="d-flex">
                          <div class="more-info">
                            <div class="form-check">
                              <input
                                id="tableAction-9"
                                v-model="action"
                                type="radio"
                                class="form-check-input"
                                name="actions"
                                value="9"
                              >
                              <label
                                class="form-check-label"
                                for="tableAction-9"
                              >Change Not former to former</label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="offcanvas-footer mt-auto">
                      <a
                        href="#"
                        title="apply actions"
                        class="btn btn-primary mb-1 d-grid w-100"
                        @click="applyActions"
                      >Apply actions</a>
                    </div>
                  </div>
                </template>
              </CompleteTable>
            </div>
          </div>
          <div
            v-else
            id="loading-bg"
          >
            <div
              class="loading"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ManageUserTraces :user="traceUser" />
    <ContactInformation
      :filter-info-sheet="filterInfoSheet"
      :user-id="userId"
      :user-status="userStatus"
    />
    <ItemInfoSheet
      :width="'85vw'"
      :table-id="15"
    >
      <template #info-sheet-title>
        Universities resume - Total: {{ Object.keys(hosts).length }} (selected: {{ Object.keys(selectedHosts).length }})
      </template>
      <template #info-sheet>
        <div class="offcanvas-body">
          <table class="table table-stripe">
            <thead>
              <tr>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                  class="cursor-pointer"
                >
                  <input
                    checked
                    type="checkbox"
                    @click="selectAll($event.target.checked)"
                  >
                </td>
                <td style="background-color: var(--icrea-brand-bg-light);">
                  Awarded
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  Email
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  University
                </td>
                <td
                  style="background-color: var(--icrea-brand-bg-light);"
                >
                  Rectors
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="host, index in hosts"
                :key="index"
              >
                <td>
                  <input
                    :checked="selectedAll"
                    type="checkbox"
                    @click="select($event.target.checked, index)"
                  >
                </td>
                <td>{{ host.user }}</td>
                <td>{{ host.email }}</td>
                <td>{{ host.host }}</td>
                <td>
                  <template v-for="rector in host.rectors">
                    {{ rector }} <br :key="rector">
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!loading"
          class="offcanvas-footer mt-auto"
        >
          <div class="row">
            <div class="col-6">
              <a
                href="#"
                title="apply actions"
                class="btn btn-primary mb-1 d-grid w-100"
                @click="send"
              >Send mails</a>
            </div>
            <div class="col-6">
              <a
                href="#"
                title="apply actions"
                class="btn btn-secondary mb-1 d-grid w-100"
                @click="exportExcel"
              >Export excel</a>
            </div>
          </div>

        </div>
        <div
          v-if="loading"
          class="offcanvas-footer mt-auto"
        >
          <a
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100 disabled"
          >Sending...</a>
        </div>

      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import ContactInformation from '../components/ContactInformation.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ManageUserTraces,
    ContactInformation,
    ItemInfoSheet,
  },
  data() {
    return {
      selectedAll: true,
      selectedHosts: {},
      hosts: {},
      sortedField: 'call',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Call',
          checked: true,
          order: 2,
        },
        {
          name: 'University',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'Erc',
          checked: true,
          order: 5,
        },
        {
          name: 'Age',
          checked: true,
          order: 6,
        },
        {
          name: 'Acceptance doc',
          checked: true,
          order: 7,
        },
        {
          name: 'Justification doc',
          checked: true,
          order: 8,
        },
        {
          name: 'Extension',
          checked: true,
          order: 9,
        },
        {
          name: 'Economic justification',
          checked: true,
          order: 10,
        },
        {
          name: 'Status',
          checked: true,
          order: 11,
        },
        {
          name: 'Edit profile',
          checked: true,
          order: 12,
        },
        {
          name: 'Active justifications',
          checked: true,
          order: 13,
        },
        {
          name: 'Web public',
          checked: true,
          order: 14,
        },
        {
          name: 'Former',
          checked: true,
          order: 15,
        },
      ],
      sortMapping: {
        Name: 'user.true_name',
        Call: 'convo_code',
        University: 'host_code',
        Area: 'area_code',
        Erc: 'erc_code',
        Age: 'age',
        Extension: 'extensionTable',
        'Acceptance doc': 'acceptance_doc_table',
        'Justification doc': 'justification_doc_table',
        'Economic justification': 'economic_justification',
        Status: 'status.status_table',
        'Active justifications': 'can_justify',
        'Edit profile': 'can_edit',
        'Web public': 'web_public',
        Former: 'former',
      },
      traceUser: {},
      filterInfoSheet: 12,
      userStatus: '',
      userId: null,
      action: '',
      selectedItems: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'academyAwarded/items',
      total: 'academyAwarded/itemsTotal',
      user: 'users/user',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      ercs: 'erc/items',
      convos: 'convo/convos',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'academy-awarded')
    if (!this.fields || this.fields.length === undefined) {
    // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'academy-awarded',
      })
    }
    await this.$store.dispatch('academyAwarded/filter', this.$store.getters['filters/filters'])
    this.loading = false

    await this.$store.dispatch('convo/fetchAll', 'academy')
    await this.$store.dispatch('institutions/filter', { university: 'University' })

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    selectAll(event) {
      this.selectedAll = event

      if (event) {
        this.selectedHosts = { ...this.hosts }
      } else {
        this.selectedHosts = {}
      }
    },
    select(checked, index) {
      if (checked) {
        Vue.set(this.selectedHosts, index, { ...this.hosts[index] })
      } else {
        Vue.delete(this.selectedHosts, index)
      }
    },
    exportExcel() {
      Vue.prototype.$export({
        url: `${Vue.prototype.$groupUrl}/convo/export-awardees`,
        filters: { users: this.selectedHosts },
        name: 'host_mails',
      })
    },
    async sendEmails() {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/awardees/get-all-awardeds`)
      this.hosts = resp.data.data
      this.selectedHosts = { ...this.hosts }
      this.$store.dispatch('modals/toggleTableClicked', 15)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async openFilters(userId, status) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.userStatus = status.status_table
      this.userId = userId
    },
    saveFilter(value, field) {
      if (field === 'calls') {
        value.map(e => {
          e.name = e.title
          return e
        })
      }
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    send() {
      console.log(this.selectedHosts)
      Vue.swal({
        title: `Do you want to send mails to the selected hosts (${Object.keys(this.selectedHosts).length})?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(result => {
        this.loading = true
        if (result.isConfirmed) {
          Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/send-mail-all-awardeds`, { users: this.selectedHosts }).then(e => {
            this.loading = false
            if (e.status == 200) {
              Vue.swal('Mails sent successfully', '', 'success')
            } else {
              Vue.swal('Error on sending mails', '', 'error')
            }
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          })
        } else {
          this.loading = false
        }
      })
    },
    async applyActions() {
      if (this.selectedItems.length === 0) {
        this.$store.dispatch('modals/toggleInfoItemSheet', false)
        this.$toastr.error('', 'No items selected!')
        return
      }

      if (!this.action) {
        this.$toastr.error('', 'No action selected!')
        return
      }

      try {
        const resp = await this.$store.dispatch('academyAwarded/actions', { users: this.selectedItems, action: this.action })
        if (resp && resp.status && resp.status === 'KO') {
          Vue.swal('Please, check that the users are correct.', '', 'error')
        } else {
          await this.$store.dispatch('academyAwarded/filter', this.$store.getters['filters/filters'])
          Vue.swal('Apply actions update successfully!', '', 'success')
        }
        this.selectedItems = []

        setTimeout(() => {
        // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.dispatch('modals/toggleInfoItemSheet', false)
      }
    },
    async downloadZip(id) {
      try {
        await this.$store.dispatch('academyAwarded/dowloadZip', id)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
